<template>
    <div class="content_container invest_private">
		<div class="content">
			<div class="banner">
				<h1>투자금융</h1>
			</div>
			<h2 class="title_product">Private Equity</h2>
			<section class="overview">
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/invest/icon_private_overview01.svg" alt="Deal Sourcing Channel" />
							<p>Deal Sourcing<br />Channel</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/invest/icon_private_overview02.svg" alt="Industry Fund Experience" />
							<p>Industry Fund<br />Experience</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/invest/icon_private_overview03.svg" alt="M&A Experience" />
							<p>M&amp;A<br />Experience</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<p>당사가 보유하고 있는 폭넓은 Deal Sourcing Channel, 풍부한 투자 운용 경험 및 체계화된 사후관리 시스템을 바탕으로 한 Industry 투자 
						경험, 다양한 M&A 경험이 접목되어 최고의 Synergy 창출을 통해 <strong>성공적인 Fund 운용</strong>
					</p>
				</div>
			</section>
			<section class="valueup">
				<h3>Value-Up Activity</h3>
				<div>
					<ol>
						<li><span>01</span>전반적인 구조조정을 통한 기업회생</li>
						<li><span>02</span>핵심사업 부문의 특화 등으로 사업 효율성 증대</li>
						<li><span>03</span>자금투자 및 채무 재조정 등을 통한 재무구조 건실화</li>
						<li><span>04</span>다양한 투자경로를 통해 지배구조 개선 및 전문경영인 영입 등</li>
					</ol>
				</div>	
			</section>
			<!-- <section class="strength">
				<h3>무궁화캐피탈의 강점</h3>
				<div class="flex_container">
					<div class="flex_item">
						<img src="@/assets/images/sub/invest/mpc_advantages01.jpg" alt="Channel" />
						<p class="title">Channel</p>
						<p class="text">신기술사업투자조합,<br />사모투자 전문회사 등<br />다양한 Vehicle 보유</p>
					</div>
					<div class="flex_item">
						<img src="@/assets/images/sub/invest/mpc_advantages02.jpg" alt="Management" />
						<p class="title">Management</p>
						<p class="text">다년간 VC, CRC Fund, PEF<br />등 다양한 펀드 운용 경험으로<br />풍부한 Know-How 보유</p>
					</div>
					<div class="flex_item">
						<img src="@/assets/images/sub/invest/mpc_advantages03.jpg" alt="Channel" />
						<p class="title">Partner</p>
						<p class="text">파트너와 연계 영업을 통한<br />우량 투자기회의 선점 가능성<br />및 Bargaining Power 보유</p>
					</div>
					<div class="flex_item">
						<img src="@/assets/images/sub/invest/mpc_advantages04.jpg" alt="Channel" />
						<p class="title">Strategy</p>
						<p class="text">증권시장을 통한 단순 매각외에<br />M&A, Recapitalization등 다양<br />한 Exit 추진 Know-how 보유</p>
					</div>
				</div>
			</section> -->
			<section class="process">
				<h3>운용 프로세스</h3>
				<ol class="flex_container">
					<li class="flex_item">
						<div class="wrapper">
							<p class="title">
								펀드 기획 및 결성
								<button type="button">&nbsp;</button>
							</p>
							<div class="m_more_contents" style="display: block;">
								<div class="img_bg">
									<p class="icon_tag"><span>icon1</span></p>
								</div>	
								<p class="underline_deco"><span>underline</span></p>
								<ul class="text">
									<li>Project/Blind Fund</li>
									<li>Vehicle 선정</li>
									<li>LP 모집 및 펀드 결성</li>
								</ul>	
							</div>
						</div>
					</li>
					<li class="flex_item">
						<div class="wrapper">
							<p class="title">
								투자대상 물색
								<button type="button">&nbsp;</button>
							</p>
							<div class="m_more_contents">
								<div class="img_bg">
									<p class="icon_tag"><span>icon2</span></p>
								</div>
								<p class="underline_deco"><span>underline</span></p>
								<ul class="text">
									<li>다양한 투자 네트워크 활용</li>
								</ul>	
							</div>
						</div>
					</li>
					<li class="flex_item">
						<div class="wrapper">
							<p class="title">
								조사분석 및 가치평가
								<button type="button">&nbsp;</button>
							</p>
							<div class="m_more_contents">
								<div class="img_bg">
									<p class="icon_tag"><span>icon3</span></p>
								</div>	
								<p class="underline_deco"><span>underline</span></p>
								<ul class="text">
									<li>사업전략 수립 및 정보 및 수집/조사</li>
									<li>산업/경쟁 분석</li>
									<li>자산/부채 실사 및 Valuation 실시</li>
								</ul>
							</div>
						</div>
					</li>
					<li class="flex_item">
						<div class="wrapper">
							<p class="title">
								투자 계약
								<button type="button">&nbsp;</button>
							</p>
							<div class="m_more_contents">
								<div class="img_bg">
									<p class="icon_tag"><span>icon4</span></p>
								</div>		
								<p class="underline_deco"><span>underline</span></p>
								<ul class="text">
									<li>최적의 투자구조 도출 및 조건 협상</li>
									<li>계약 체결 및 투자집행</li>
								</ul>
							</div>
						</div>
					</li>
					<li class="flex_item">
						<div class="wrapper">
							<p class="title">
								모니터링 및 수익 시현
								<button type="button">&nbsp;</button>
							</p>
							<div class="m_more_contents">
								<div class="img_bg">
									<p class="icon_tag"><span>icon5</span></p>
								</div>	
								<p class="underline_deco"><span>underline</span></p>
								<ul class="text">
									<li>정기/수시 모니터링을 통한 자문 및 사후 관리</li>
									<li>시장상황에 적합한 Exit 방안 수립 및 수익시현</li>
								</ul>	
							</div>
						</div>
					</li>
				</ol>
			</section>

			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

