<template>
    <div class="online_banner">
        <p>상호간 유리한 지속 가능 WIN-WIN 대출</p>			
    </div>
    <section class="online">
        <div class="online_text_wrap">
            <h3>온라인상담</h3>
            <div>
                <p><router-link to="/customer/onlinecontact">온라인 상담 바로가기</router-link></p>
            </div>	
        </div>	
    </section>
</template>

<script>
export default {
    name : "Online"
}
</script>